import { Submission, Attachments } from '@/types/form/prior-auth/submission'
import { SubmissionData } from '@/types/api/prior-auth/submission'
import { SubmissionType } from '@/types/api/prior-auth/submission-type'
import { Urgency } from '@/types/api/prior-auth/urgency'

import { mapPatient } from './patient'
import { mapProvider } from './provider'
import { mapFacility } from './facility'
import { mapEncounter } from './encounter'
import { mapAdditionalInformationData } from './additional-information'
import { mapInsurance } from './insurance'
import { mapAttachment } from './attachment'

import { Attachment as APIAttachment } from '@/types/api/prior-auth/common/attachment'
import { Attachment as UIAttachment } from '@/types/form/prior-auth/attachment'

export function mapSubmission(submission: Submission, flags?: Set<string>): SubmissionData {
  const mappedSubmission = {
    urgency: (submission?.details?.urgency as Urgency) || null,
    type: (submission?.details?.type as SubmissionType) || null,
    previous_submission: submission?.details?.previousSubmission || null,
    out_of_network_rationale: submission?.details?.outOfNetworkRationale || null,
    patient: mapPatient(submission?.patient),
    insurance: submission?.insurances?.map(insurance => mapInsurance(insurance)) || null,
    providers: {
      servicing: mapProvider(submission?.providers?.servicing || null),
      ordering: mapProvider(submission?.providers?.ordering || null),
      pcp: mapProvider(submission?.providers?.pcp || null),
      referring: mapProvider(submission?.providers?.referring || null),
    },
    facilities: {
      servicing: mapFacility(submission?.facilities?.servicing),
      ordering: mapFacility(submission.facilities?.ordering),
    },
    encounter: mapEncounter(submission?.encounter || null),
  }

  if (!flags?.has('ms19224ShowAdditionalQuestionsSection')) {
    mappedSubmission['additional_information'] = mapAdditionalInformationData(
      submission.additionalInformation || []
    )
  }

  return mappedSubmission
}

export function mapAttachments(attachments: Attachments): APIAttachment[] {
  const mappedAttachments: APIAttachment[] = []
  for (const [key, value] of Object.entries(attachments)) {
    if (value && key === 'miscAttachments') {
      value
        .map((attachment: UIAttachment) => mapAttachment(attachment))
        .forEach((attachment: APIAttachment) => mappedAttachments.push(attachment))
    } else if (value) {
      mappedAttachments.push(mapAttachment(value))
    }
  }
  return mappedAttachments
}
