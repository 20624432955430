import { State } from './state'
import { Payer } from '@/types/wizard/payer'
import { Provider } from '@/types/form/prior-auth/provider'
import { Facility } from '@/types/form/prior-auth/facility'
import { ProcedureCode } from '@/types/form/prior-auth/procedures'
import { Section } from '@/types/form/sections/section'
import { RequiredFieldMap, WizardSection } from '@/modules/wizard/types'
import { Procedures } from '../../../models/encounters/procedures/fe'
import { ServiceType } from '@/types/api/prior-auth/service-type'

export function getPayerList(state: State): Payer[] {
  return state.payers as Payer[]
}

export function getServiceTypeOptions(state: State): ServiceType[] {
  return state.serviceTypeOptions
}

export function getCurrentSection(state: State): Section {
  return state.section
}

export function prefillOrderingProvider(state: State): Provider {
  return state.prefillOrderingProvider
}

export function prefillServicingProvider(state: State): Provider {
  return state.prefillServicingProvider
}

export function prefillReferringProvider(state: State): Provider {
  return state.prefillReferringProvider
}

export function prefillPcpProvider(state: State): Provider {
  return state.prefillPcpProvider
}

export function prefillServicingFacility(state: State): Facility {
  return state.prefillServicingFacility
}

export function prefillOrderingFacility(state: State): Facility {
  return state.prefillOrderingFacility
}

export function getServiceCategories(state: State): Record<string, string>[] {
  return state.serviceCategories || []
}

export function getBaseProcedureCodes(state: State): ProcedureCode[] {
  return state.baseProcedureCodes
}

export function getSubmissionId(state: State): string {
  const session = { ...state.session }
  return session.submissionId || ''
}

export function getClientId(state: State): string {
  return state.session.clientId || ''
}

export function getCorrelationId(state: State): string {
  return state.session.correlationId || ''
}

export function getFeatureFlags(state: State): Set<string> {
  return state.featureFlags
}

export function getOutOfNetworkStatus(state: State): boolean {
  return state.session?.networkStatus === 'OUT_OF_NETWORK'
}

export function getNetworkStatus(state: State): string | null {
  return state.session?.networkStatus || null
}

export function getSections(state: State): Array<Section> {
  return state.showOptionalSectionsAndFields ? state.allSections : state.sections
}

export function getRequiredFields(state: State): RequiredFieldMap {
  return state.requiredFields
}

export function getCheckForRequiredSections(state: State): boolean {
  return state.checkForRequiredSections
}

export const getPreviousSection =
  (state: State) =>
  (currentSection: WizardSection): WizardSection => {
    const sections = state.showOptionalSectionsAndFields ? state.allSections : state.sections
    const index = sections.findIndex((section: Section) => section.section === currentSection)
    return index === 0 ? sections[0].section : sections[index - 1].section
  }

export const getNextSection =
  (state: State) =>
  (currentSection: WizardSection): WizardSection => {
    const sections = state.showOptionalSectionsAndFields ? state.allSections : state.sections
    const index = sections.findIndex((section: Section) => section.section === currentSection)
    return index === sections.length
      ? sections[sections.length].section
      : sections[index + 1].section
  }

export function getPrepopulationData(state: State) {
  return state.prepopulationData
}

export function getFormPopulationData(state: State) {
  return state.prepopulationData?.modal || {}
}

export function isLoadingTransaction(state: State): boolean {
  return state.isLoadingTransaction
}

export function getSession(state: State) {
  return state.session
}

function isAuthorized(state: State): boolean {
  const sessionCopy = { ...state?.session }
  return !!sessionCopy?.token
}

function isSectionLoading(state: State): boolean {
  return state.isSectionLoading
}

function showOptionalSectionsAndFields(state: State): boolean {
  return state.showOptionalSectionsAndFields
}

function isSubmitted(state: State): boolean {
  return state.isSubmitted
}

function getErrorMessage(state: State): string | null {
  return state.errorMessage
}

function getErrorButtonMessage(state: State): string | null {
  return state.errorButtonMessage
}

function getReVerify(state: State): boolean | null {
  return state.session.reVerify || null
}

function getErrorShouldAddCorrelation(state: State): boolean {
  return state.errorShouldAddCorrelation
}

function getProcedures(state: State): Procedures {
  return state.procedures
}

function getLastActive(state: State): Date | null {
  return state?.session?.lastActive || null
}

function getQuestionnaire(state: State): any {
  return state?.questionnaire
}

function isErrorReported(state: State): boolean {
  return state.errorReported
}

export const getters = {
  getFeatureFlags,
  getCurrentSection,
  getPayerList,
  getServiceCategories,
  getBaseProcedureCodes,
  getSubmissionId,
  getCorrelationId,
  getClientId,
  getOutOfNetworkStatus,
  getSections,
  getRequiredFields,
  getPreviousSection,
  getProcedures,
  getNextSection,
  getPrepopulationData,
  getSession,
  getNetworkStatus,
  getErrorMessage,
  getFormPopulationData,
  getErrorButtonMessage,
  getErrorShouldAddCorrelation,
  getCheckForRequiredSections,
  getLastActive,
  getReVerify,
  getServiceTypeOptions,
  getQuestionnaire,
  isAuthorized,
  isErrorReported,
  isLoadingTransaction,
  isSectionLoading,
  isSubmitted,
  prefillOrderingProvider,
  prefillServicingProvider,
  prefillReferringProvider,
  prefillPcpProvider,
  prefillServicingFacility,
  prefillOrderingFacility,
  showOptionalSectionsAndFields,
}
