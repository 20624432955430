import { SubmissionState } from './state'
import { Provider } from '@/types/form/prior-auth/provider'
import { Facility } from '@/types/form/prior-auth/facility'
import { checkProperties } from '@/modules/helpers/validate'
import * as WizardModels from '../../../models/wizard/fe'
import { Insurance } from '@/types/form/prior-auth/insurance'
import { Id } from '@/types/form/prior-auth/id'

export function submissionState(state: SubmissionState): SubmissionState {
  return state
}

export function orderingProvider(state: SubmissionState): Provider {
  return { ...state.providers.ordering } as Provider
}

export function isThereOrderingProvider(state: SubmissionState): boolean {
  if (!state.providers?.ordering) return false

  return (
    checkProperties(state.providers.ordering.contact) ||
    checkProperties(state.providers.ordering.address) ||
    !!state.providers.ordering.information?.npi ||
    !!state.providers.ordering.information?.tin ||
    (state.providers.ordering.information?.providerName?.type === 'individual' &&
      !!state.providers.ordering.information?.providerName?.firstName) ||
    (state.providers.ordering.information?.providerName?.type === 'individual' &&
      !!state.providers.ordering.information?.providerName?.lastName) ||
    (state.providers.ordering.information?.providerName?.type === 'organization' &&
      !!state.providers.ordering.information?.providerName?.organizationName)
  )
}

export function servicingProvider(state: SubmissionState): Provider {
  return { ...state.providers.servicing } as Provider
}

export function isThereServicingProvider(state: SubmissionState): boolean {
  if (!state.providers?.servicing) return false

  return (
    checkProperties(state.providers.servicing.contact) ||
    checkProperties(state.providers.servicing.address) ||
    !!state.providers.servicing.information?.npi ||
    !!state.providers.servicing.information?.tin ||
    (state.providers.servicing.information?.providerName?.type === 'individual' &&
      !!state.providers.servicing.information?.providerName?.firstName) ||
    (state.providers.servicing.information?.providerName?.type === 'individual' &&
      !!state.providers.servicing.information?.providerName?.lastName) ||
    (state.providers.servicing.information?.providerName?.type === 'organization' &&
      !!state.providers.servicing.information?.providerName?.organizationName)
  )
}

export function pcpProvider(state: SubmissionState): Provider {
  return { ...state.providers.pcp } as Provider
}

export function isTherePcpProvider(state: SubmissionState): boolean {
  if (!state.providers?.pcp) return false

  return (
    checkProperties(state.providers.pcp.contact) ||
    checkProperties(state.providers.pcp.address) ||
    !!state.providers.pcp.information?.npi ||
    !!state.providers.pcp.information?.tin ||
    (state.providers.pcp.information?.providerName?.type === 'individual' &&
      !!state.providers.pcp.information?.providerName?.firstName) ||
    (state.providers.pcp.information?.providerName?.type === 'individual' &&
      !!state.providers.pcp.information?.providerName?.lastName) ||
    (state.providers.pcp.information?.providerName?.type === 'organization' &&
      !!state.providers.pcp.information?.providerName?.organizationName)
  )
}

export function referringProvider(state: SubmissionState): Provider {
  return { ...state.providers.referring } as Provider
}

export function isThereReferringProvider(state: SubmissionState): boolean {
  if (!state.providers?.referring) return false

  return (
    checkProperties(state.providers.referring.contact) ||
    checkProperties(state.providers.referring.address) ||
    !!state.providers.referring.information?.npi ||
    !!state.providers.referring.information?.tin ||
    (state.providers.referring.information?.providerName?.type === 'individual' &&
      !!state.providers.referring.information?.providerName?.firstName) ||
    (state.providers.referring.information?.providerName?.type === 'individual' &&
      !!state.providers.referring.information?.providerName?.lastName) ||
    (state.providers.referring.information?.providerName?.type === 'organization' &&
      !!state.providers.referring.information?.providerName?.organizationName)
  )
}

export function orderingFacility(state: SubmissionState): Facility {
  return { ...state.facilities.ordering }
}

export function isThereOrderingFacility(state: SubmissionState): boolean {
  if (!state.facilities?.ordering) return false

  return (
    checkProperties(state.facilities.ordering.contact) ||
    checkProperties(state.facilities.ordering.address) ||
    checkProperties(state.facilities.ordering.information)
  )
}

export function servicingFacility(state: SubmissionState): Facility {
  return { ...state.facilities.servicing }
}

export function isThereServicingFacility(state: SubmissionState): boolean {
  if (!state.facilities?.servicing) return false

  return (
    checkProperties(state.facilities.servicing.contact) ||
    checkProperties(state.facilities.servicing.address) ||
    checkProperties(state.facilities.servicing.information)
  )
}

export function getAdditionalInformation(
  state: SubmissionState
): WizardModels.AdditionalInformationProperty[] {
  return state.additionalInformation
}

export function getInsurances(state: SubmissionState): (Insurance & Id)[] {
  return state.insurances
}

export function getSubmission(state: SubmissionState) {
  return {
    details: state.details,
    patient: state.patient,
    insurances: state.insurances,
    providers: state.providers,
    facilities: state.facilities,
    encounter: state.encounter,
    additionalInformation: state.additionalInformation,
  }
}

export function getAttachments(state: SubmissionState) {
  return {
    signedOrder: state.signedOrder,
    historyAndPhysical: state.historyAndPhysical,
    miscAttachments: state.miscAttachments,
  }
}

export function submissionDetails(state: SubmissionState) {
  return state.details
}

export function getQuestionnaireResponse(state: SubmissionState) {
  return state.questionnaireResponse
}

export const getters = {
  submissionState,
  orderingProvider,
  isThereOrderingProvider,
  servicingProvider,
  isThereServicingProvider,
  pcpProvider,
  isTherePcpProvider,
  referringProvider,
  isThereReferringProvider,
  orderingFacility,
  isThereOrderingFacility,
  servicingFacility,
  isThereServicingFacility,
  getAdditionalInformation,
  getInsurances,
  getSubmission,
  getAttachments,
  submissionDetails,
  getQuestionnaireResponse,
}
