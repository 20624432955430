import { RequiredFieldMap } from '@/modules/wizard/types'
import { State } from './state'
import { Payer } from '@/types/wizard/payer'
import { Provider } from '@/types/form/prior-auth/provider'
import { Facility } from '@/types/form/prior-auth/facility'
import { ProcedureCode } from '@/types/form/prior-auth/procedures'
import { emptySession, Session } from '@/types/wizard/session'
import * as jwt from 'jsonwebtoken'
import { Section } from '@/types/form/sections/section'
import * as WizardModels from '../../../models/wizard/fe'
import { logRocketIdentifyUser, track } from '@/modules/logrocket/logrocket'
import { Procedures } from '../../../models/encounters/procedures/fe'
import { ServiceType } from '@/types/api/prior-auth/service-type'

function setSection(state: State, section: Section) {
  state.section = section
}

function setServiceTypeOptions(state: State, options: ServiceType[]) {
  state.serviceTypeOptions = options
}

function setPayers(state: State, value: Payer[]) {
  state.payers = value
}

function setPrefillOrderingProvider(state: State, value: Provider) {
  state.prefillOrderingProvider = value
}

function setPrefillServicingProvider(state: State, value: Provider) {
  state.prefillServicingProvider = value
}

function setPrefillReferringProvider(state: State, value: Provider) {
  state.prefillReferringProvider = value
}

function setPrefillPcpProvider(state: State, value: Provider) {
  state.prefillPcpProvider = value
}

function setPrefillServicingFacility(state: State, value: Facility) {
  state.prefillServicingFacility = value
}

function setPrefillOrderingFacility(state: State, value: Facility) {
  state.prefillOrderingFacility = value
}

function setServiceCategories(state: State, value: Record<string, string>[] | null) {
  state.serviceCategories = value
}

function setBaseProcedureCodes(state: State, value: ProcedureCode[]) {
  state.baseProcedureCodes = value
}

function setUserEmail(state: State, value?: string) {
  state.userEmail = value || null
}

function clearSession(state: State) {
  state.session = { ...emptySession }
  state.expiresAt = -1
}

interface ExtendedJwtPayload extends jwt.JwtPayload {
  email?: string
  user_email?: string
}

function setSessionTokenInfo(state: State, token?: string | null) {
  if (token) {
    try {
      const decodedJwt = jwt.decode(token) as ExtendedJwtPayload
      const email = decodedJwt.email || decodedJwt.user_email || ''
      const newSessionInfo = {
        userEmail: email || state.userEmail || '',
        expiresAt: decodedJwt.exp || -1,
      }
      state.session = { ...state.session, ...newSessionInfo }

      if (!state.userEmail && email) {
        state.userEmail = email
      }

      logRocketIdentifyUser(state.userEmail || null)
    } catch (_) {
      console.error('invalid session token')
    }
  }
}

function setToken(state: State, token: string) {
  state.session = { ...state.session, token }
  track('LoginEvent')
}

function setClientId(state: State, clientId: string) {
  state.session = { ...state.session, clientId }
}

function setCorrelationId(state: State, correlationId: string) {
  state.session = { ...state.session, correlationId }
}

function setApiServer(state: State, server: string) {
  state.config = { apiServer: server }
}

function setReVerify(state: State, value: boolean) {
  state.session = { ...state.session, reVerify: value }
}

function setSubmissionId(state: State, submissionId: string) {
  state.session = { ...state.session, submissionId }
}

function setLoadingTransaction(state: State, isLoading: boolean) {
  state.isLoadingTransaction = isLoading
}

function setPayer(state: State, payer: string) {
  state.session = { ...state.session, payer }
}

function setPlan(state: State, plan: string) {
  state.session = { ...state.session, plan }
}

function setState(state: State, sessionState: string) {
  state.session = { ...state.session, state: sessionState }
}

function setHealthCareCategory(state: State, healthCareCategory: string) {
  state.session = { ...state.session, healthCareCategory }
}

function setNetworkStatus(state: State, networkStatus: string | boolean) {
  if (typeof networkStatus === 'boolean') {
    state.session = {
      ...state.session,
      networkStatus: networkStatus ? 'IN_NETWORK' : 'OUT_OF_NETWORK',
    }
  } else {
    state.session = { ...state.session, networkStatus }
  }
}

function setPrimaryCode(state: State, primaryCode: string) {
  state.session = { ...state.session, primaryCode }
}

function setPrimaryCodeModifier(state: State, modifier: string) {
  state.session = { ...state.session, primaryCodeModifier: modifier }
}

function setAdditionalCodes(state: State, additionalCodes: string[]) {
  state.session = { ...state.session, additionalCodes }
}

function setFormId(state: State, formId: string) {
  state.session = { ...state.session, formId }
}

function setFeatureFlags(state: State, featureFlags: string[]) {
  const tempFlags = state.featureFlags
  const newFlags = new Set<string>(tempFlags.values())

  for (const flag of featureFlags) {
    newFlags.add(flag)
  }

  state.featureFlags = newFlags
}

function setPrepopulationData(state: State, data: any) {
  if (data?.submission_id) {
    state.session.submissionId = data?.submission_id
  }
  state.prepopulationData = data
}

function setSessionDetails(state: State, value: Session): void {
  state.session = { ...state.session, ...value }
}

function setSections(state: State, value: Section[]) {
  state.sections = value
}

function setAllSections(state: State, value: Section[]) {
  state.allSections = value
}

function setSectionValue(state: State, value: Section) {
  state.sections = state.sections.map((section: Section) => {
    if (value.section === section.section) {
      return value
    }
    return section
  })
  state.allSections = state.allSections.map((section: Section) => {
    if (value.section === section.section) {
      return value
    }
    return section
  })
}

function setRequiredFields(state: State, value: RequiredFieldMap): void {
  state.requiredFields = value
}

function setCheckForRequiredSections(state: State, value: boolean): void {
  state.checkForRequiredSections = value
}

function setAdditionalInformation(
  state: State,
  value: WizardModels.AdditionalInformationProperty[]
): void {
  state.additionalInformation = value
}

function setSectionLoading(state: State, value: boolean): void {
  state.isSectionLoading = value
}

function setShowOptionalSectionsAndFields(state: State, value: boolean): void {
  state.showOptionalSectionsAndFields = value
}

function setIsSubmitted(state: State, value: boolean): void {
  state.isSubmitted = value
}

function setErrorMessage(state: State, message: string | null): void {
  state.errorMessage = message
}

function setErrorButtonMessage(state: State, message: string | null): void {
  state.errorButtonMessage = message
}

function setErrorShouldAddCorrelation(state: State, shouldAddCorrelation: boolean): void {
  state.errorShouldAddCorrelation = shouldAddCorrelation
}

function setProcedures(state: State, procedures: Procedures): void {
  state.procedures = procedures
}

function setErrorReported(state: State, value: boolean): void {
  state.errorReported = value
}

function setLastActive(state: State, value: Date | null): void {
  state.session = { ...state.session, lastActive: value }
}

function setQuestionnaire(state: State, value: any): void {
  state.questionnaire = value
}

export const mutations = {
  clearSession,
  setAdditionalCodes,
  setAdditionalInformation,
  setApiServer,
  setClientId,
  setCorrelationId,
  setCheckForRequiredSections,
  setErrorMessage,
  setErrorButtonMessage,
  setErrorShouldAddCorrelation,
  setErrorReported,
  setFormId,
  setFeatureFlags,
  setIsSubmitted,
  setPayers,
  setPrefillOrderingProvider,
  setPrefillServicingProvider,
  setPrefillReferringProvider,
  setPrefillPcpProvider,
  setPrefillServicingFacility,
  setPrefillOrderingFacility,
  setPayer,
  setPlan,
  setServiceTypeOptions,
  setSection,
  setServiceCategories,
  setBaseProcedureCodes,
  setSessionTokenInfo,
  setLastActive,
  setLoadingTransaction,
  setState,
  setHealthCareCategory,
  setNetworkStatus,
  setPrimaryCode,
  setPrimaryCodeModifier,
  setProcedures,
  setSubmissionId,
  setPrepopulationData,
  setSessionDetails,
  setRequiredFields,
  setReVerify,
  setAllSections,
  setSections,
  setSectionValue,
  setSectionLoading,
  setShowOptionalSectionsAndFields,
  setToken,
  setUserEmail,
  setQuestionnaire,
}
