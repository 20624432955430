import { RequiredFieldMap, WizardSection } from '@/modules/wizard/types'
import { Payer } from '@/types/wizard/payer'
import { Facility } from '@/types/form/prior-auth/facility'
import { Provider } from '@/types/form/prior-auth/provider'
import { ProcedureCode } from '@/types/form/prior-auth/procedures'
import { Session } from '@/types/wizard/session'
import { Section } from '@/types/form/sections/section'
import { ServiceType } from '@/types/api/prior-auth/service-type'
import * as WizardModels from '../../../models/wizard/fe'
import { Procedures } from '../../../models/encounters/procedures/fe'

export const state = {
  userEmail: null as string | null,
  section: { section: WizardSection.DETAILS, dirty: true } as Section,
  sections: [] as Section[],
  allSections: [] as Section[],
  requiredFields: {} as RequiredFieldMap,
  checkForRequiredSections: false,
  payers: [] as Payer[] | null,
  serviceCategories: [] as Record<string, string>[] | null,
  serviceTypeOptions: [] as ServiceType[],
  baseProcedureCodes: [] as ProcedureCode[],
  prefillOrderingProvider: {} as Provider,
  prefillServicingProvider: {} as Provider,
  prefillReferringProvider: {} as Provider,
  prefillPcpProvider: {} as Provider,
  prefillServicingFacility: {} as Facility,
  prefillOrderingFacility: {} as Facility,
  procedures: { primaryCode: null, codes: [] } as Procedures,
  showOptionalSectionsAndFields: false,
  expiresAt: -1,
  isLoadingTransaction: false,
  isSectionLoading: false,
  isSubmitted: false,
  session: {
    token: null,
    payer: null,
    plan: null,
    state: null,
    healthCareCategory: null,
    networkStatus: null,
    memberId: null,
    groupNumber: null,
    clientId: null,
    correlationId: null,
    submissionId: null,
    standaloneUser: true,
    primaryCode: null,
    primaryCodeModifier: null,
    additionalCodes: null,
    lastActive: null,
    reVerify: null,
  } as Session,
  config: { apiServer: '' },
  featureFlags: new Set() as Set<string>,
  additionalInformation: [] as WizardModels.AdditionalInformationProperty[],
  prepopulationData: {} as any,
  errorMessage: null as string | null,
  errorButtonMessage: null as string | null,
  errorShouldAddCorrelation: false,
  errorReported: false,
  questionnaire: {} as any,
}

export type State = typeof state
